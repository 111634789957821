// Returns the "other" food map marker icon in URI encoded svg format

export default (width = 40, height = 40) => {
  return 'data:image/svg+xml;utf8,' + encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 280 369"><path d="M274,139.79C274,65.9,214,6,140,6S6,65.9,6,139.79a133.85,133.85,0,0,0,95.91,128.29L140,363l38.09-94.92A133.87,133.87,0,0,0,274,139.79Z" 
  fill="#fff" stroke="#ddb426" stroke-linejoin="round" stroke-width="12"/><path d="M156.48,206.68c1.73-6.73,1.74-13.63,1.5-20-.56-19.36-3.4-38-6.6-55.54l-.31-2.39c-.56-3-1.9-5-4.09-6.07-3.21-1.48-6.47.21-7.42.73l-.06.13-3.4,2c-6.1,3.59-12.47,7.38-18.69,11.23-9.74,6.07-22.3,14.37-33.31,24.35-3.66,3.29-7.16,6.63-9.39,11-6,11.35-5.87,23.28.2,35.4a47.18,47.18,0,0,0,22.38,21.6h0c14.29,6.63,30.34,6.05,43-1.72C148.68,222.33,154.26,215.29,156.48,206.68Z" 
  fill="#fff"/><path d="M156.48,206.68c1.72-6.74,1.74-13.63,1.5-20-.56-19.36-3.4-38-6.6-55.54l-.31-2.39c-.56-3-1.9-5-4.09-6.07-3.21-1.48-6.47.21-7.42.73l-.06.13-3.4,2c-6.1,3.59-12.47,7.38-18.69,11.23-9.74,6.07-22.3,14.37-33.31,24.35-3.66,3.29-7.16,6.63-9.39,11-6,11.35-5.87,23.28.2,35.4a47.18,47.18,0,0,0,22.38,21.6h0c14.29,6.63,30.34,6.05,43-1.72C148.68,222.33,154.26,215.28,156.48,206.68Zm-19.7,16.07c-11,6.79-24.82,7.26-37.22,1.51a40.87,40.87,0,0,1-19.49-18.67c-5.26-10.45-5.35-20.93-.23-30.75,1.85-3.63,4.83-6.58,8.36-9.6,10.75-9.78,23-17.88,32.56-23.9,6-3.78,12.39-7.58,18.63-11.1l3.19-1.89c.06-.13.21-.06.27-.19a5.37,5.37,0,0,1,1.71-.66,3.25,3.25,0,0,1,.62,1.74l.37,2.25c3,17.13,5.94,35.65,6.47,54.68.15,6.16.17,12.59-1.39,18.61C148.9,212.32,144.17,218.31,136.78,222.75Z" 
  fill="#134b8e"/><path d="M209.7,65.05l-22.46-8.41a7.92,7.92,0,0,0-4.51-6.08L179,48.82a7.91,7.91,0,0,0-7.56.48L150.48,37.58a6.15,6.15,0,0,0-5.17,11.11l22.47,8.41a7.93,7.93,0,0,0,4.51,6.08l3.76,1.74a7.93,7.93,0,0,0,7.56-.48l20.93,11.71a6.14,6.14,0,0,0,5.16-11.1Z" 
  fill="#ddb426"/><path d="M213.56,125.33c5.36-11.52.95-26.48-10.59-31.83L179,82.38l1.89-4c1.76-3.79-1.72-9.14-7.79-12s-12.4-2-14.16,1.77L157,72.21,133.6,61.34c-11.53-5.35-25.83.94-31.18,12.45-9.43-4.37-21.4-.06-26,9.94L70.73,95.86,62.31,114l17.06,7.92c9.43,4.37,20.84-.19,25.49-10.19l.4-.87a17.26,17.26,0,0,1,22.9-8.36l11.46,5.31A15.79,15.79,0,0,1,163,118.61l11.38,5.28a17.2,17.2,0,0,1,8.37,22.86l-.32.91c-4.65,10-.78,21.64,8.65,26l17.06,7.91,8.42-18.1,5.65-12.13C226.86,141.35,223,129.7,213.56,125.33Z" 
  fill="#ddb426"/><path d="M108,212.94a2.77,2.77,0,0,1-3.74,1.16C91,208,84.92,193.24,90.45,181.36a2.91,2.91,0,0,1,5.25,2.44c-4.16,9.3.66,20.69,10.85,25.42A2.86,2.86,0,0,1,108,212.94Z" 
  fill="#b42025"/></svg>`)
};
