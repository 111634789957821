import React from "react";

const WaterIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="233"
      height="233"
      viewBox="0 0 233 233"
    >
      <circle
        cx="116.5"
        cy="116.5"
        r="111.5"
        transform="translate(-48.26 116.5) rotate(-45)"
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="10"
      />
      <path
        d="M161.54,135.26c-1.3-7-4.26-13.39-7.21-19.19-8.86-17.71-19.52-33.78-30-48.66L123,65.33c-1.81-2.53-3.94-3.87-6.41-3.87-3.61,0-5.9,3-6.55,3.87v.15l-2.3,3.27c-4.11,6-8.37,12.2-12.48,18.45-6.4,9.82-14.44,22.91-20.34,36.9-2,4.62-3.78,9.22-3.94,14.29-.66,13.09,4.59,24.1,15.43,32.73a48.22,48.22,0,0,0,30,10.41h0c16.08,0,30.69-7.44,39.05-20.08C161.06,153.12,163.19,144.19,161.54,135.26Z"
        fill="#fff"
        stroke={color}
        strokeWidth="4"
      />
      <path
        d="M161.54,135.26c-1.3-7-4.26-13.39-7.21-19.19-8.86-17.71-19.52-33.78-30-48.66L123,65.33c-1.81-2.53-3.94-3.87-6.41-3.87-3.61,0-5.9,3-6.55,3.87v.15l-2.3,3.27c-4.11,6-8.37,12.2-12.48,18.45-6.4,9.82-14.44,22.91-20.34,36.9-2,4.62-3.78,9.22-3.94,14.29-.66,13.09,4.59,24.1,15.43,32.73a48.22,48.22,0,0,0,30,10.41h0c16.08,0,30.69-7.44,39.05-20.08C161.06,153.12,163.19,144.19,161.54,135.26Zm-11.32,23.36c-7.21,11-19.85,17.4-33.8,17.4a41.8,41.8,0,0,1-26.08-8.92c-9.37-7.44-14-17.11-13.46-28.42.16-4.16,1.64-8.18,3.6-12.5C86.23,112.5,94.11,99.7,100.34,90c3.94-6.09,8.21-12.34,12.47-18.29l2.13-3.13c0-.15.17-.15.17-.29A5.4,5.4,0,0,1,116.42,67s.49.14,1.31,1.34l1.32,1.93c10.16,14.58,20.84,30.5,29.53,47.91,2.79,5.65,5.58,11.6,6.73,17.86C157,143.74,155.15,151.33,150.22,158.62Zm-30.84,3.27a2.82,2.82,0,0,1-3,2.68c-14.93,0-26.91-11-26.91-24.4a3,3,0,0,1,5.91,0c.16,10.41,9.52,18.9,21,18.9A2.93,2.93,0,0,1,119.38,161.89Z"
        fill={color}
        stroke={color}
        strokeWidth="4"
      />
    </svg>
  );
};

export default WaterIcon;
