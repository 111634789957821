import React from "react";

const FoodCongregationFilterIcon = ({ disabled = false }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="185.76"
      height="212.2"
      viewBox="0 0 185.76 212.2"
    >
      <path
        d="M124.38,109a50.16,50.16,0,0,0-5-13.4c-6.2-12.37-13.66-23.59-21-34l-.92-1.46a5.52,5.52,0,0,0-4.48-2.7c-2.52,0-4.12,2.08-4.58,2.7v.11l-1.61,2.28c-2.87,4.16-5.86,8.52-8.73,12.89a168.71,168.71,0,0,0-14.23,25.77c-1.37,3.22-2.64,6.44-2.75,10C60.57,120.31,64.24,128,71.82,134a33.78,33.78,0,0,0,21,7.27c11.25,0,21.47-5.19,27.32-14C124.05,121.45,125.54,115.21,124.38,109Zm-7.91,16.31c-5,7.69-13.89,12.16-23.65,12.16a29.21,29.21,0,0,1-18.24-6.24C68,126,64.81,119.27,65.16,111.37a23.51,23.51,0,0,1,2.52-8.73c4-9.56,9.54-18.49,13.89-25.25,2.76-4.26,5.74-8.62,8.73-12.78l1.49-2.18c0-.11.12-.11.12-.21a3.9,3.9,0,0,1,.91-.94s.35.11.92.94l.92,1.35c7.11,10.18,14.58,21.3,20.66,33.46,2,4,3.9,8.11,4.7,12.47C121.17,114.91,119.91,120.2,116.47,125.29Z"
        fill={disabled ? "#b0b0b0" : "#000"}
      />
      <path
        d="M116.19,1.28,99.11,2.58A5.66,5.66,0,0,0,94.36,0h-3a5.66,5.66,0,0,0-4.75,2.58L69.57,1.28a4.21,4.21,0,0,0-4,4.37,4.2,4.2,0,0,0,4,4.37l17.08-1.3a5.65,5.65,0,0,0,4.75,2.59h3a5.65,5.65,0,0,0,4.75-2.59L116.19,10a4.21,4.21,0,0,0,4-4.37A4.22,4.22,0,0,0,116.19,1.28Z"
        fill={disabled ? "#b0b0b0" : "#000"}
      />
      <path
        d="M138,38.2c0-9.29-7.55-17.86-16.86-17.86H101.52v-1.8c0-3-3.87-5.4-8.64-5.4s-8.64,2.42-8.64,5.4v1.8H65.11c-9.31,0-16.85,8.57-16.85,17.86A14.58,14.58,0,0,0,34,52.81V77.22H47.81c7.61,0,13.77-6.55,13.77-14.62v-.69A12.61,12.61,0,0,1,74.2,49.31h9.25a11.56,11.56,0,0,1,18.86,0h9.18a12.61,12.61,0,0,1,12.62,12.6l.07.69c0,8.07,6.16,14.62,13.77,14.62h13.77V52.81C151.72,44.75,145.56,38.2,138,38.2Z"
        fill={disabled ? "#b0b0b0" : "#000"}
      />
      <path
        d="M94.89,127.58a2,2,0,0,1-2.07,1.87c-10.44,0-18.82-7.69-18.82-17a2.08,2.08,0,0,1,4.14,0c.1,7.27,6.65,13.2,14.68,13.2A2,2,0,0,1,94.89,127.58Z"
        fill={disabled ? "#b0b0b0" : "#000"}
      />
      <path
        d="M73.76,173.61c-6.94-12.15-33.62-26.19-38.95-52.73-14.16,1.34-7.17,25.06,2.41,37.57,3.83,5,2.85,4.82-1.9,1-8.8-7.13-19.14-14.06-20.87-23.42-4.07-22,1.22-39.17-13-39.18-1.5,13.36-2.87,43,1.26,55.42,4,12,11,18.06,15.72,22.58,10.34,9.86,26.07,14.41,31.38,31a133.57,133.57,0,0,0,29.51,6.4C79.81,199.24,79.77,183.36,73.76,173.61Z"
        fill={disabled ? "#b0b0b0" : "#000"}
      />
      <path
        d="M167.29,174.81C172,170.29,179,164.27,183,152.23c4.13-12.38,2.75-42.06,1.25-55.42-14.18,0-8.89,17.23-13,39.18-1.74,9.36-12.07,16.29-20.88,23.42-4.74,3.86-5.73,4-1.9-1,9.59-12.51,16.57-36.23,2.42-37.58-5.33,26.55-32,40.58-38.95,52.74-6,9.75-6,25.63-5.59,38.59a133.57,133.57,0,0,0,29.51-6.4C141.22,189.22,157,184.67,167.29,174.81Z"
        fill={disabled ? "#b0b0b0" : "#000"}
      />
    </svg>
  );
};

export default FoodCongregationFilterIcon;
