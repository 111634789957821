// Returns the "congregation" food map marker icon in URI encoded svg format

export default (width = 40, height = 40) => {
  return 'data:image/svg+xml;utf8,' + encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 280 369">
  <path d="M274,139.79C274,65.9,214,6,140,6S6,65.9,6,139.79a133.85,133.85,0,0,0,95.91,128.29L140,363l38.09-94.92A133.87,133.87,0,0,0,274,139.79Z" 
  fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="12"/><path d="M171.5,149.72a49.76,49.76,0,0,0-5-13.41c-6.2-12.36-13.66-23.59-21-34l-.93-1.45a5.49,5.49,0,0,0-4.47-2.7c-2.53,0-4.13,2.07-4.59,2.7v.1l-1.61,2.29c-2.87,4.16-5.85,8.52-8.73,12.88a169.67,169.67,0,0,0-14.23,25.77c-1.37,3.23-2.63,6.45-2.75,10-.46,9.14,3.21,16.83,10.79,22.86a33.78,33.78,0,0,0,21,7.27c11.25,0,21.47-5.19,27.32-14C171.17,162.19,172.66,156,171.5,149.72ZM163.59,166c-5.05,7.69-13.89,12.16-23.65,12.16A29.27,29.27,0,0,1,121.7,172c-6.55-5.19-9.77-11.95-9.41-19.84a23.09,23.09,0,0,1,2.52-8.73c4-9.56,9.53-18.5,13.88-25.26,2.76-4.25,5.75-8.62,8.73-12.77l1.49-2.19c0-.1.12-.1.12-.2a3.73,3.73,0,0,1,.91-.94s.35.1.92.94l.92,1.35c7.11,10.18,14.58,21.3,20.66,33.46,1.95,3.94,3.9,8.1,4.7,12.47C168.3,155.64,167,160.94,163.59,166Z" 
  fill="#000"/><path d="M163.31,42l-17.08,1.3a5.64,5.64,0,0,0-4.75-2.58h-3a5.64,5.64,0,0,0-4.75,2.58L116.69,42a4.38,4.38,0,0,0,0,8.74l17.08-1.3A5.66,5.66,0,0,0,138.52,52h3a5.66,5.66,0,0,0,4.75-2.58l17.08,1.3a4.38,4.38,0,0,0,0-8.74Z" 
  fill="#000"/><path d="M185.07,78.94c0-9.29-7.55-17.87-16.85-17.87H148.64V59.28c0-3-3.87-5.41-8.64-5.41s-8.64,2.42-8.64,5.41v1.79H112.23c-9.3,0-16.85,8.58-16.85,17.87A14.58,14.58,0,0,0,81.16,93.55V118H94.93c7.61,0,13.77-6.54,13.77-14.61v-.7A12.62,12.62,0,0,1,121.32,90h9.25a11.56,11.56,0,0,1,18.86,0h9.18a12.62,12.62,0,0,1,12.62,12.6l.07.7c0,8.07,6.16,14.61,13.77,14.61h13.77V93.55C198.84,85.48,192.68,78.94,185.07,78.94Z" 
  fill="#000"/><path d="M142,168.32a2,2,0,0,1-2.07,1.87c-10.44,0-18.82-7.69-18.82-17.05a2.08,2.08,0,0,1,4.14,0c.11,7.27,6.65,13.2,14.68,13.2A2.06,2.06,0,0,1,142,168.32Z" 
  fill="#000"/><path d="M120.88,214.34c-6.93-12.15-33.62-26.19-38.95-52.73-14.15,1.34-7.17,25.06,2.42,37.58,3.83,5,2.84,4.81-1.91,1-8.8-7.14-19.13-14.07-20.87-23.42-4.07-21.95,1.22-39.18-13-39.18-1.51,13.36-2.88,43,1.25,55.42,4,12,11,18.05,15.72,22.58,10.35,9.85,26.07,14.4,31.38,31a132.44,132.44,0,0,0,29.51,6.4C126.93,240,126.89,224.09,120.88,214.34Z" 
  fill="#000"/><path d="M214.41,215.55C219.17,211,226.12,205,230.14,193c4.13-12.38,2.75-42.06,1.25-55.42-14.18,0-8.89,17.23-13,39.18-1.73,9.35-12.07,16.28-20.88,23.42-4.74,3.85-5.73,4-1.9-1,9.59-12.52,16.57-36.24,2.42-37.58-5.33,26.55-32,40.58-38.95,52.73-6,9.75-6.05,25.64-5.59,38.59a132.44,132.44,0,0,0,29.51-6.4C188.35,230,204.07,225.4,214.41,215.55Z" 
  fill="#000"/></svg>`)
};
