import React from "react";

const FoodOtherFilterIcon = ({ disabled = false }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="161.87"
      height="196.62"
      viewBox="0 0 161.87 196.62"
    >
      <path
        d="M94.17,169.59c1.73-6.74,1.74-13.64,1.51-20-.56-19.36-3.41-38-6.6-55.54l-.31-2.39c-.56-3-1.91-5-4.09-6.06-3.21-1.49-6.48.2-7.43.72l-.06.14-3.39,2C67.7,92,61.33,95.79,55.1,99.64,45.36,105.71,32.81,114,21.79,124c-3.65,3.29-7.15,6.63-9.39,11.06-6,11.34-5.87,23.27.2,35.39A47.24,47.24,0,0,0,35,192.05h0c14.29,6.62,30.34,6,43-1.73C86.37,185.23,92,178.19,94.17,169.59Z"
        fill="#fff"
      />
      <path
        d="M94.17,169.59c1.73-6.74,1.74-13.64,1.51-20-.56-19.36-3.41-38-6.6-55.54l-.31-2.39c-.56-3-1.91-5-4.09-6.06-3.21-1.49-6.48.2-7.43.72l-.06.14-3.39,2C67.7,92,61.32,95.79,55.1,99.64,45.36,105.71,32.81,114,21.79,124c-3.65,3.29-7.15,6.63-9.39,11.06-6,11.34-5.87,23.27.2,35.39A47.24,47.24,0,0,0,35,192.05h0c14.29,6.62,30.34,6,43-1.73C86.37,185.23,92,178.19,94.17,169.59ZM74.48,185.65c-11,6.79-24.83,7.26-37.23,1.51A40.94,40.94,0,0,1,17.76,168.5c-5.25-10.46-5.34-20.93-.23-30.76,1.86-3.63,4.83-6.58,8.36-9.6,10.76-9.77,23-17.88,32.56-23.9,6-3.78,12.39-7.57,18.64-11.09l3.18-1.9c.06-.13.22-.06.28-.19a5.57,5.57,0,0,1,1.71-.65s.38.33.61,1.73l.37,2.25c3,17.13,5.94,35.65,6.48,54.68.14,6.16.17,12.59-1.4,18.61C86.6,175.22,81.86,181.21,74.48,185.65Z"
        fill={disabled ? "#b0b0b0" : "#134b8e"}
      />
      <path
        d="M147.4,28l-22.47-8.41a7.89,7.89,0,0,0-4.51-6.08l-3.75-1.74a7.91,7.91,0,0,0-7.56.48L88.17.49a5.91,5.91,0,0,0-7.7,3.17A5.9,5.9,0,0,0,83,11.59L105.48,20A7.87,7.87,0,0,0,110,26.08l3.75,1.74a7.91,7.91,0,0,0,7.56-.48l20.93,11.71a5.9,5.9,0,0,0,7.7-3.18A5.88,5.88,0,0,0,147.4,28Z"
        fill={disabled ? "#b0b0b0" : "#ddb426"}
      />
      <path
        d="M151.26,88.23c5.36-11.52.94-26.47-10.59-31.82l-24-11.13,1.88-4.05c1.76-3.79-1.72-9.14-7.78-11.95s-12.4-2-14.17,1.78l-1.88,4.05L71.3,24.24c-11.54-5.35-25.83.94-31.19,12.46-9.43-4.37-21.39-.07-26,9.93L8.42,58.77,0,76.87l17.07,7.91c9.42,4.37,20.83-.19,25.49-10.19l.4-.86a17.25,17.25,0,0,1,22.9-8.37l11.46,5.32s6.89-4.15,14.49-.62a16.43,16.43,0,0,1,8.88,11.45l11.38,5.28a17.21,17.21,0,0,1,8.38,22.87l-.32.9c-4.65,10-.78,21.64,8.64,26l17.07,7.91,8.42-18.1,5.64-12.14C164.56,104.25,160.69,92.6,151.26,88.23Z"
        fill={disabled ? "#b0b0b0" : "#ddb426"}
      />
      <path
        d="M45.71,175.84A2.75,2.75,0,0,1,42,177c-13.27-6.15-19.37-20.85-13.84-32.74a2.92,2.92,0,0,1,5.26,2.44c-4.16,9.3.65,20.69,10.85,25.42A2.86,2.86,0,0,1,45.71,175.84Z"
        fill={disabled ? "#b0b0b0" : "#b42025"}
      />
    </svg>
  );
};

export default FoodOtherFilterIcon;
