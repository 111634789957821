// Returns the map marker icon in URI encoded svg format

export default (type, width = 40, height = 40) => {
  const color = () => {
    switch (type) {
      case "Public":
        return "#27aae1"
      case "Private":
        return "#f4d411"
      case "Restricted":
        return "#cd3642"
      case "Private-Shared": // fall through
      case "Semi-public":
        return "#2ab673"
      default:
        return "#b0b0b0"
    }
  }

  return 'data:image/svg+xml;utf8,' + encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 279 369">
  <path d="M273,139.79C273,65.9,213.23,6,139.5,6S6,65.9,6,139.79a133.79,133.79,0,0,0,95.55,128.29l38,94.92,37.95-94.92A133.81,133.81,0,0,0,273,139.79Z" 
  fill="#fff" stroke="${color()}" stroke-linejoin="round" stroke-width="12"/><path d="M178,183.23c-1.11-6-3.64-11.45-6.17-16.41-7.57-15.15-16.69-28.9-25.68-41.62l-1.12-1.78c-1.55-2.17-3.37-3.31-5.48-3.31-3.09,0-5,2.54-5.61,3.31v.13l-2,2.79c-3.51,5.1-7.16,10.44-10.67,15.79-5.48,8.39-12.36,19.59-17.41,31.55-1.68,3.95-3.22,7.9-3.36,12.22-.56,11.2,3.92,20.62,13.19,28a41.26,41.26,0,0,0,25.68,8.9h0c13.76,0,26.25-6.36,33.4-17.17C177.61,198.5,179.44,190.86,178,183.23Z" 
  fill="#fff"/><path d="M178,183.23c-1.11-6-3.64-11.45-6.17-16.41-7.57-15.15-16.69-28.9-25.68-41.62l-1.12-1.78c-1.55-2.17-3.37-3.31-5.48-3.31-3.09,0-5,2.54-5.61,3.31v.13l-2,2.79c-3.51,5.1-7.16,10.44-10.67,15.79-5.48,8.39-12.36,19.59-17.41,31.55-1.68,3.95-3.22,7.9-3.36,12.22-.56,11.2,3.92,20.62,13.19,28a41.26,41.26,0,0,0,25.68,8.9h0c13.76,0,26.25-6.36,33.4-17.17C177.61,198.5,179.44,190.86,178,183.23Zm-9.68,20c-6.17,9.42-17,14.88-28.91,14.88a35.76,35.76,0,0,1-22.31-7.63c-8-6.36-11.94-14.63-11.51-24.3.14-3.57,1.4-7,3.09-10.69,4.91-11.71,11.65-22.65,17-30.93,3.37-5.21,7-10.56,10.66-15.65l1.83-2.67c0-.13.14-.13.14-.26a4.67,4.67,0,0,1,1.12-1.14s.42.12,1.12,1.14l1.13,1.65c8.69,12.48,17.83,26.09,25.26,41,2.38,4.83,4.77,9.92,5.75,15.27C174.1,190.49,172.56,197,168.34,203.21ZM142,206a2.41,2.41,0,0,1-2.53,2.29c-12.77,0-23-9.42-23-20.87a2.54,2.54,0,0,1,5.06,0c.14,8.9,8.14,16.16,18,16.16A2.51,2.51,0,0,1,142,206Z" 
  fill="${color()}"/><path d="M111,62l20.88-1.6a6.92,6.92,0,0,0,5.81,3.17h3.62a6.91,6.91,0,0,0,5.81-3.17L168,62a5.37,5.37,0,0,0,0-10.71l-20.88,1.59a6.93,6.93,0,0,0-5.81-3.16h-3.62a6.93,6.93,0,0,0-5.81,3.16L111,51.33A5.37,5.37,0,0,0,111,62Z" 
  fill="${color()}"/><path d="M193.26,97.72c0-11.11-9-21.34-20.1-21.34h-23.1V72.47c0-3.65-4.73-6.62-10.56-6.62s-10.56,3-10.56,6.62v3.91H106.38c-11.1,0-20.1,10.23-20.1,21.34-9.08,0-17,7.81-17,17.45v29.16H85.74c9.07,0,16.43-7.82,16.43-17.46V126a15,15,0,0,1,15-15.05h11a13.78,13.78,0,0,1,22.5,0H161.7A15,15,0,0,1,176.75,126l.08.83c0,9.64,7.36,17.46,16.43,17.46h16.43V115.17C209.69,105.53,202.33,97.72,193.26,97.72Z" 
  fill="${color()}"/></svg>`)
};
